.App .navbar-brand img {
  width: 32px;
  height: 32px;
}

.App .navbar-brand .media .brand-name {
  margin-top: 0.2rem;
}

.App .footer {
  font-size: 85%;
}

.App .footer a:hover{
  text-decoration: none;
}

.App .password-mask .btn {
  min-width: 80px;
}

.App .jumbotron .octicon {
  font-size: 70px;
}

.App .btn > .octicon {
  font-size: 100%;
}

.App .container-appear {
  opacity: 0.01;
}

.App .container-appear.container-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.App .list-item-appear {
  opacity: 0.01;
}

.App .list-item-appear.list-item-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.App .list-item-enter {
  opacity: 0.01;
}

.App .list-item-enter.list-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.App .list-item-leave {
  opacity: 1;
}

.App .list-item-leave.list-item-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.App h4.header-title .octicon-dashboard {
  font-size: 26px;
}

.App .spin-octicon {
  vertical-align: middle;
  font-size: 1rem;
  margin-bottom: 3px;
  width: 12px;
  margin-right: .3rem;
}
