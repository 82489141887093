@media (prefers-color-scheme:dark) {
  // We can't use mixins and imports so we use another theme - auto, which uses the dark theme settings
  .theme-auto {
    @import '../dark/theme.scss';
    @import '../colors.scss';
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~react-toastify/dist/ReactToastify.css';
    @import '../colors.scss';
    @include root-colors;
    @import '../common.scss';
    @import '../dark/overrides.scss';
  }
}
