.About {
  background-color: var(--white);
}

.About .primary-divider {
  margin: 40px auto 20px auto;
  height: 3px;
  width: 80px;
  background-color: var(--primary);
}
