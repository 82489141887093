.Home {
  background-color: var(--white);
}

.Home .primary-divider {
  margin: 40px auto;
  height: 3px;
  width: 80px;
  background-color: var(--primary);
}
