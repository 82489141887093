.Testimonials .lead {
  font-size: 1.75rem;
}

.Testimonials footer {
  font-size: 1.25rem;
}

.Testimonials img.logo {
  max-height: 50px;
}
