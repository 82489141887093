
.NotificationBar {
  position: relative;
}

.NotificationBar .notification {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0.6rem 2.75rem;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-weight: 500;
}

.NotificationBar .notification .action {
  
  text-decoration: underline;
  padding: 0px;
}

.NotificationBar button:not(.btn) {
  border: 0;
  color: white;
  background: none;
}

.NotificationBar button.close-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
