@mixin root-colors {
  @each $color, $value in $theme-colors {
    --#{$color}: #{$value} !important;
  }
}

@each $color, $value in $theme-colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}