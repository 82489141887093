.CtaLink .octicon {
  margin-left: 0.25rem;
  -webkit-transition: -webkit-transform 200ms;
  -webkit-transition: transform 200ms;
  transition: transform 200ms;
  
}

.CtaLink:hover .octicon {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}
