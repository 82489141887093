.Security .octicon-check {
  font-size: 20px;
  padding-top: 1px;
}

.Security a:hover .octicon {
  text-decoration: underline;
}

.Security .octicon-shield {
  font-size: 48px;
}