$white: #111827;
$black: #f9fafb;

$gray-100: #111827 !default;
$gray-200: #1f2937 !default;
$gray-300: #374151 !default;
$gray-400: #4b5563 !default;
$gray-500: #6b7280 !default;
$gray-600: #9ca3af !default;
$gray-700: #d1d5db !default;
$gray-800: #e5e7eb !default;
$gray-900: #f8f9fa !default;

// Colors
$theme-colors: (
  "blue": #007bff,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #e83e8c,
  "red": #dc3545,
  "orange": #fd7e14,
  "yellow": #ffc107,
  "green": #28a745,
  "teal": #20c997,
  "cyan": #17a2b8,
  "primary": #2563eb,
  "secondary": $gray-400,
  "success": #00B374,
  "info": #5578eb,
  "warning": #ffb822,
  "danger": #fd397a,
  "white": $white,
  "gray-light": $gray-300,
  "gray": $gray-600,
  "gray-dark": $gray-900,
  "light": rgba($black, .075),
  "dark": $gray-900,
  "input-color": $gray-700,
  "input-placeholder": $gray-600
);

// Dark theme - switch light with dark
$theme-colors: map-merge($theme-colors, (
  "primary-dark": lighten(map-get($theme-colors, 'primary'),35%),
  "primary-light": darken(map-get($theme-colors, 'primary'),8%),
  "secondary-dark": lighten(map-get($theme-colors, 'secondary'),33%),
  "secondary-light": darken(map-get($theme-colors, 'secondary'),8%),
  "success-dark": lighten(map-get($theme-colors, 'success'),52%),
  "success-light": darken(map-get($theme-colors, 'success'),8%),
  "info-dark": lighten(map-get($theme-colors, 'info'),33%),
  "info-light": darken(map-get($theme-colors, 'info'),8%),
  "warning-dark": lighten(map-get($theme-colors, 'warning'),35%),
  "warning-light": darken(map-get($theme-colors, 'warning'),8%),
  "danger-dark": lighten(map-get($theme-colors, 'danger'),37%),
  "danger-light": darken(map-get($theme-colors, 'danger'),8%),
  "dark-dark": lighten(map-get($theme-colors, 'dark'),3%),
  "dark-light": darken(map-get($theme-colors, 'dark'),8%)
));

$font-family-base:Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

$yiq-contrasted-threshold: -150;
$modal-backdrop-opacity: 0.3;

$enable-shadows: false;

// Body colors
$body-bg: $gray-100;
$body-color: $gray-800;
$headings-color: $black;

// Jumbotron colors
$jumbotron-bg: $white;

// Spinner
$spinner-animation-speed: 0.6s;
$spinner-border-width: 0.125em;
$spinner-border-width-sm: 0.125em;

$spinner-width: 2em;
$spinner-width-sm: 1em;
$spinner-width-md: 1.5em;
$spinner-width-xl: 2.5em;

$alert-bg-level: 0;
$alert-border-level: 0;

$enable-responsive-font-sizes: true;

$headings-font-weight: 800;

$badge-padding-y: .35em;
$badge-padding-x: .65em;
$badge-font-size: .75em;
$badge-font-weight: 600;

// Dropdown
$dropdown-item-padding-y: 0.45rem;

// Anchor link colors
$link-color: map-get($theme-colors, 'primary');
$link-hover-color: map-get($theme-colors, 'primary-light');

// Navbar
$navbar-light-color: map-get($theme-colors, 'primary');
$navbar-light-hover-color: map-get($theme-colors, 'primary-light');
$navbar-light-active-color: map-get($theme-colors, 'primary');

$navbar-light-brand-color: map-get($theme-colors, 'primary');
$navbar-light-brand-hover-color: map-get($theme-colors, 'primary-light');

// Card
$card-cap-bg: $gray-200;
$card-bg: $gray-100;
$card-border-color: rgba($black, .125);

// Code
$kbd-box-shadow: inset 0 -.1rem 0 0 rgba($black, .2);
$kbd-color: $body-color;
$kbd-bg: rgba($gray-200, .5);
// Mark
$mark-bg: map-get($theme-colors, 'yellow');
