.AccountNavItem .nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

.AccountNavItem .nav-link .avatar {
  position: relative;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.AccountNavItem.dropdown .dropdown-menu {
  min-width: 240px;
  padding-top: 0px;
}

.AccountNavItem.dropdown .octicon {
  min-width: 20px;
}

.AccountNavItem.dropdown .details {
  padding: 20px 20px 10px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.AccountNavItem.dropdown .details .display-name {
  font-weight: 600;
  font-size: 95%;
}

.AccountNavItem.dropdown .org-name {
  font-weight: 600;
  font-size: 95%;
}

.AccountNavItem.dropdown .details .avatar-container {
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
  box-shadow: none;
}

.AccountNavItem.dropdown .details .avatar-container .avatar {
  position: relative;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
