// Custom variable values only support SassScript inside `#{}`.
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert {
  color: color-yiq(theme-color("danger"));
}

.primary-divider {
  background-color: theme-color("primary");
}

.navbar .navbar-nav {
  background-color: transparent;
}

.navbar .navbar-nav .nav-link {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.navbar .nav-link.dashboard-button {
  border: 1px solid rgba($navbar-light-color, 0.3);
}

.navbar .navbar-nav .nav-link:hover {
  background-color: rgba($navbar-light-hover-color, 0.06);
}

.btn-outline-secondary {
  color: $body-color;
}

.btn-outline-secondary:disabled, 
.btn-outline-secondary.disabled {
  color: theme-color("gray");
}

.card {
  box-shadow: 0px 0px 13px 0px rgba($black, 0.05);
}

.card.highlight {
  box-shadow: 0 15px 35px rgba($black, 0.08);
}

// Special spinner sizes
.spinner-border {
  animation: $spinner-animation-speed linear infinite spinner-border;
}

.spinner-border-md {
  width: $spinner-width-md;
  height: $spinner-width-md;
}

.spinner-border-xl {
  width: $spinner-width-xl;
  height: $spinner-width-xl;
}

time:not(.no-dash), .dashed, .modal .dashed {
  border-bottom: 1px dashed $body-color;
}

.dropdown .dropdown-item.primary {
  color: theme-color("primary");
}

.dropdown .dropdown-item.primary:hover,
.dropdown .dropdown-item.primary:active {
  color: color-yiq(theme-color("primary"));
  background-color: theme-color("primary");
}

.dropdown .dropdown-item.danger {
  color: theme-color("danger");
}

.dropdown .dropdown-item.danger:hover,
.dropdown .dropdown-item.danger:active {
  color: color-yiq(theme-color("danger"));
  background-color: theme-color("danger");
}

.dropdown .dropdown-item.warning {
  color: theme-color("warning");
}

.dropdown .dropdown-item.warning:hover,
.dropdown .dropdown-item.warning:active {
  color: color-yiq(theme-color("warning"));
  background-color: theme-color("warning");
}

.dropdown .dropdown-item.success {
  color: theme-color("success");
}

.dropdown .dropdown-item.success:hover,
.dropdown .dropdown-item.success:active {
  color: color-yiq(theme-color("success"));
  background-color: theme-color("success");
}

code, .text-monospace {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

.btn {
  -webkit-transition: .2s cubic-bezier(.175, .885, .32, 1.2) padding !important;
  -moz-transition: .2s cubic-bezier(.175, .885, .32, 1.2) padding !important;
  -ms-transition: .2s cubic-bezier(.175, .885, .32, 1.2) padding !important;
  -o-transition: .2s cubic-bezier(.175, .885, .32, 1.2) padding !important;
  transition: .2s cubic-bezier(.175, .885, .32, 1.2) padding !important;

  position: relative;

  .spinner-border {
    right: .6em;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
}

.Toastify__toast {
  min-height: inherit;
  font-size: 95%;
}

.Toastify__toast .octicon {
  font-size: 85%;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.75;
}

.Toastify__toast .octicon:hover {
  opacity: 1;
}

.animate {
  animation-duration: 250ms;
}

.custom-switch, 
.custom-switch .custom-control-input,
.custom-switch .custom-control-label {
  cursor: pointer;
}

.custom-checkbox, 
.custom-checkbox .custom-control-input,
.custom-checkbox .custom-control-label {
  cursor: pointer;
}

.custom-checkbox .custom-control-label {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.nav.menu .nav-link {
  line-height: 1.7rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-bottom: -1px;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  border-bottom: 2px solid transparent;
  padding: 0.5rem 1rem 0.35rem 1rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: -1px;
}

.nav.menu .nav-link .badge {
  vertical-align: unset;
}

.nav.menu .nav-link:hover {
  background-color: rgba(theme-color('primary'), 0.06);
}

.nav.menu .nav-link.active {
  background-color: rgba(theme-color('primary'), 0.06);
  border-bottom: 2px solid theme-color('primary');
}

.text-disabled {
  color: rgba($gray-500, 0.6);
}

.nav-item.selected .nav-link {
  background-color: rgba(theme-color('info'), 0.1);
}

.nav-item .nav-link:hover {
  background-color: theme-color('light');
}

.nav-item.paused .nav-link,
.nav-item.inactive .nav-link {
  background-image: linear-gradient(45deg,rgba(128,128,128,0.1) 25%,transparent 25%,transparent 50%,rgba(128,128,128,0.1) 50%,rgba(128,128,128,0.1) 75%,transparent 75%,transparent);
  background-size: 40px 40px;
}

.nav-item.deleting .nav-link,
.nav-item.deleting .nav-link:hover,
.nav-item.pausing .nav-link,
.nav-item.pausing .nav-link:hover,
.nav-item.rotating .nav-link,
.nav-item.rotating .nav-link:hover {
  background-color: rgba($danger, 0.2);
}

.nav-item.updating .nav-link,
.nav-item.updating .nav-link:hover,
.nav-item.resuming .nav-link,
.nav-item.resuming .nav-link:hover,
.nav-item.running .nav-link,
.nav-item.running .nav-link:hover,
.nav-item.logging .nav-link,
.nav-item.logging .nav-link:hover {
  background-color: rgba(theme-color('info'), 0.1);
}

.StripeElement {
  padding-top: 0.56rem;
}

.StripeElement--focus {
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  box-shadow: $input-focus-box-shadow;
  outline: 0;
}

.StripeElement--invalid {
  border-color: theme-color('danger');

  &.StripeElement--focus {
    border-color: theme-color('danger');
    box-shadow: 0 0 0 $input-focus-width rgba(theme-color('danger'), .25);
  }
}

@mixin search-icon($color, $size) {
  background-image: escape-svg(url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color}" viewBox="0 0 $size $size" width="$size" height="$size"><path fill-rule="evenodd" d="M11.5 7a4.499 4.499 0 11-8.998 0A4.499 4.499 0 0111.5 7zm-.82 4.74a6 6 0 111.06-1.06l3.04 3.04a.75.75 0 11-1.06 1.06l-3.04-3.04z"></path></svg>'));
}

input.is-search {
  padding-left: calc(1.2em + 1.015rem) !important;
  @include search-icon($gray-600, 16);
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.375rem) center;
  background-size: 16px 16px;
} 

kbd {
  @include border-radius($border-radius-lg);
  border: .05rem solid rgba($black, .2);
  box-shadow: $kbd-box-shadow;
}